import { DownloadOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AdminService } from "src/resources/AdminService";

const CampMembers = () => {
  const [members, setMembers] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.campMembers().then((data) => {
        console.log(data.data);
        setMembers(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Member",
      dataIndex: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Year Level",
      dataIndex: "yearlevel",
    },
    {
      title: "Special Dietary",
      dataIndex: "special",
    },
    {
      title: "Main Applicant",
      dataIndex: "camper",
    },
    {
      title: "Relation",
      dataIndex: "relation",
    },
  ];
  const headers = [
    { label: "Member", key: "name" },
    { label: "Gender", key: "gender" },
    { label: "Age", key: "age" },
    { label: "Year Level", key: "yearlevel" },
    { label: "Special Dietary", key: "special" },
    { label: "Main Applicant", key: "camper" },
    { label: "Relation", key: "relation" },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={members}
        loading={members === null}
        bordered
        size="small"
        pagination={false}
        title={() =>
          members && (
            <div className="flex justify-between items-center">
              <span>Registered Members. {members.length} total.</span>
              <CSVLink
                className="ant-btn ant-btn-primary   ml-1"
                data={members}
                headers={headers}
                filename={"Camp-Members.csv"}
              >
                <DownloadOutlined /> Export Data
              </CSVLink>
            </div>
          )
        }
      />
    </div>
  );
};

export default CampMembers;
